import { ONBOARDING_MODAL_ACTIONS } from "../../constants/actions";

const { OPEN_ONBOARDING_MODAL, CLOSE_ONBOARDING_MODAL, SET_ENTERPRISE_FLOW } =
  ONBOARDING_MODAL_ACTIONS;

import { OnboardingModalState } from '../../types/state/reducers/onboardingModalReducerTypes';

const initialState: OnboardingModalState = {
  isOnboardingModalOpen: false,
  isEnterpriseFlow: false
};

export const onboardingModal = (state = initialState, action: { type: string}) => {
  switch (action.type) {
    case OPEN_ONBOARDING_MODAL:
      return {
        ...state,
        isOnboardingModalOpen: true
      };
    case CLOSE_ONBOARDING_MODAL:
      return {
        ...state,
        isOnboardingModalOpen: false
      };
    case SET_ENTERPRISE_FLOW:
      return {
        ...state,
        isEnterpriseFlow: true
      }
    default:
      return state;
  }
};